/* ::-webkit-scrollbar {
  width: 8px;
  height: 3px;
}
::-webkit-scrollbar-track {
  background-color: #232323;
}
::-webkit-scrollbar-track-piece {
  background-color: #000;
}
::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: rgb(39, 39, 39);
  border-radius: 3px;
} */
::-webkit-scrollbar {
  display: none;
}
